<template>
	<div>
		<v-snackbar v-model="alerta" color="red">
			Período entre datas não pode ser superior a 30 dias
			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="alerta = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<Table
			:pgLimitGet="pgLimit"
			backendDirectory="pedido/listar"
			:busca="{
                busca: busca.busca || null,
                dtini: busca.dtinicio || null,
                dtfim: busca.dtfim || null,
                idfilial: parseInt(busca.idfilial) || null,
            }"
			:filter="true"
			:headers="{
                idpedido: {nome: 'Nº Pedido', tipo: permissao('pedido_get') ? 'link' : 'texto', link: '/venda/pedido/get/'},
                filial: {nome: 'Filial', tipo: 'texto'},
                cliente: {nome: 'Cliente', tipo: 'objeto', campos: ['idcliente','nome'], divisor: ' - '},
                dtpedido: {nome: 'Data pedido', tipo: 'texto'},
                valortotal: {nome: 'Valor pedido', tipo: 'dinheiro'},
                observacao: {nome: 'Observação', tipo: 'texto'},
				flagimportado: {nome: 'Status', tipo: 'boolean', true: 'Efetivado', false: 'Pendente'},
            }"
		>
			<v-col class="d-none d-md-block px-1">
				<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
			</v-col>
			<v-col class="d-none d-md-block px-1">
				<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
			</v-col>
			<v-col class="d-none d-md-block px-1">
				<v-autocomplete
					:items="filiaisFiltro"
					item-text="filial"
					item-value="idfilial"
					label="Filial"
					outlined
					dense
					v-model="busca.idfilial"
				></v-autocomplete>
			</v-col>
			<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
				<v-sheet class="text-center" height="100%">
					<v-row class="pa-3">
						<v-col cols="12" class="pr-3 pr-md-0 mb-n8">
							<v-select
								clearable
								:items="filiaisFiltro"
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								v-model="busca.idfilial"
							></v-select>
						</v-col>
						<v-col cols="6">
							<InputDatePicker
								v-model="busca.dtinicio"
								label="Data inicial"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-col cols="6">
							<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
						</v-col>
						<v-col cols="6">
							<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
						</v-col>
						<v-col cols="6">
							<v-btn text class="mt-n3" color="primary" @click="keydown = !keydown, sheet = !sheet">Filtrar</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-bottom-sheet>
			<v-col class="pl-1">
				<v-text-field
					class="d-flex d-md-none"
					v-model="busca.busca"
					label="Buscar"
					outlined
					dense
					append-outer-icon="mdi-menu"
					@click:append-outer="sheet = !sheet"
					@keydown.enter="keydown = !keydown"
				/>
				<v-text-field
					class="d-none d-md-block"
					v-model="busca.busca"
					label="Buscar"
					outlined
					dense
					@keydown.enter="keydown = !keydown"
				/>
			</v-col>
		</Table>
	</div>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import Table from "../Widgets/Table";

export default {
	name: "VendaPedidoLista",
	mixins: [mixinFilial],
	components: { Table, InputDatePicker },
	data: () => ({
		alerta: false,
		sheet: false,
		keydown: false,
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date().getDate()}`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date().getDate()}`,
			idfilial: 0,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
		},
	},
	watch: {
		"busca.dtfim": function () {
			const dtinicio = new Date(this.busca.dtinicio).getTime();
			const dtfim = new Date(this.busca.dtfim).getTime();
			const diff = Math.abs(dtinicio - dtfim);
			const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
			const month = 28 * (1000 * 60 * 60 * 24);
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
			if (days > 30) {
				this.alerta = true;
				this.busca.dtinicio = `${new Date(
					dtfim - month
				).getFullYear()}-${
					(new Date(dtfim - month).getMonth() + 1 < 10 ? "0" : "") +
					(new Date(dtfim - month).getMonth() + 1)
				}-${
					(new Date(dtfim - month).getDate() < 10 ? "0" : "") +
					new Date(dtfim - month).getDate()
				}`;
			}
		},
		"busca.dtinicio": function () {
			const dtinicio = new Date(this.busca.dtinicio).getTime();
			const dtfim = new Date(this.busca.dtfim).getTime();
			const diff = Math.abs(dtinicio - dtfim);
			const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
			const month = 28 * (1000 * 60 * 60 * 24);
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
			if (days > 30) {
				this.alerta = true;
				this.busca.dtfim = `${new Date(
					dtinicio + month
				).getFullYear()}-${
					(new Date(dtinicio + month).getMonth() + 1 < 10
						? "0"
						: "") +
					(new Date(dtinicio + month).getMonth() + 1)
				}-${
					(new Date(dtinicio + month).getDate() < 10 ? "0" : "") +
					new Date(dtinicio + month).getDate()
				}`;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>